import React, { useEffect } from 'react';
import { createUseStyles, useTheme } from "react-jss";
import { useHistory } from 'react-router';
import { ReactComponent as Logo } from '../assets/logo.svg';
import useWindowDimensions from '../hooks/useWindowDimensions';
import Button from '../components/Button';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '80px',
    '@media screen and (max-width: 1200px)': {
      flexDirection: 'column'
    },
  },
  text: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    color: ({ theme }) => theme.textColor,
    margin: '30px 0',
  },
  image: {
    backgroundImage: 'url(mainTree.png)',
    width: '390px',
    height: '620px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    '@media screen and (max-width: 1200px)': {
      display: 'none'
    },
  },
  blackFriday: {
    backgroundColor: 'rgb(32, 32, 32)',
    color: 'white',
    padding: '10px 450px 10px 50px',
    borderRadius: '20px',
    marginLeft: '50px',
    fontWeight: '700',
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    display: 'flex',
    position: 'absolute',
    right: '-420px',
    top: '15px',

    '&>span': {
      margin: '0 10px',
      display: 'block',
    }
  },
  info: {
    maxWidth: '520px',
    marginLeft: '135px',
    '@media screen and (max-width: 1200px)': {
      margin: '20px 30px',
    },
  },
  logo: {
    height: '102px',
    '& svg': {
      height: '100%',
      width: 'auto'
    }
  }
});

const MainPage = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory()
  const { width } = useWindowDimensions();

  useEffect(() => {
    document.title = 'Christmas products'
  }, [])

  return (
    <div className={classes.root}>
      {/* <div className={classes.blackFriday}>
        <span>Black Friday!</span>
        {width > 900 &&
          <>
            <span>-5% на все!</span>
            <span>-10% на замовлення від 16 000 грн </span>
            <span>-15% на замовлення від 26 000 грн</span>
          </>
        }
      </div> */}
      <div className={classes.image}>

      </div>
      <div className={classes.info}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.text}>
          Вже понад 5 років ми займаємося продажем новорічних товарів за ціною виробника. Вся продукція, яку ми пропонуємо, виготовляється з високоякісної первинної сировини і проходить санітарну гігієну. Серед наших товарів ви можете обрати ялинку, гілку та вінок з тієї ж колекції. Ми постійно слідкуємо за трендами та кожного року оновлюємо наш асортимент
        </div>
        <div>
          <Button onClick={() => history.push('/catalogue')}>почати покупки</Button>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
