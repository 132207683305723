import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles, useTheme } from "react-jss";
import { getOne } from '../api/Product';
import Layout from '../components/Layout';
import Button from '../components/Button';
import { ReactComponent as HeightIcon } from '../assets/height.svg';
import { ReactComponent as LengthIcon } from '../assets/length.svg';
import { ReactComponent as DiameterIcon } from '../assets/diameter.svg';
import { ReactComponent as WidthIcon } from '../assets/width.svg';
import Loader from '../components/Loader';
import ProductCollectionItem from '../components/ProductCollectionItem';
import ProductSelect from '../components/ProductSelect';
import ProductCounter from '../components/ProductCount';
import ProductMainSpecification from '../components/ProductMainSpecification';
import { get, update } from '../api/Cart';
import { useHistory } from 'react-router';
import { ReactComponent as CheckIcon } from '../assets/check.svg';
import useWindowDimensions from '../hooks/useWindowDimensions';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      minHeight: '700px',
    },
    '@media screen and (max-width: 900px)': {
      flexDirection: 'column'
    },
  },
  leftSide: {
    flex: '1 1 70%',
    width: '70%',
    minWidth: '70%',
    maxWidth: '70%',
    position: 'relative',

    '@media screen and (max-width: 1200px)': {
      flex: '1 1 60%',
      width: '60%',
      minWidth: '60%',
      maxWidth: '60%',
    },

    '@media screen and (max-width: 900px)': {
      flex: '1 1 100%',
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      height: '500px',
      minHeight: '500px',
      paddingBottom: '30px',
    }
  },
  rightSide: {
    flex: '1 1 30%',
    width: '30%',
    minWidth: '30%',
    maxWidth: '30%',
    paddingLeft: '50px',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',

    '@media screen and (max-width: 1200px)': {
      flex: '1 1 40%',
      width: '40%',
      minWidth: '40%',
      maxWidth: '40%',
    },

    '@media screen and (max-width: 900px)': {
      flex: '1 1 100%',
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      paddingLeft: '0',
    }
  },
  mainImage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,

    '&>img': {
      maxHeight: '100%',
      maxWidth: '100%',
      height: 'auto',
      width: 'auto',
    },
  },
  productName: {
    textTransform: 'uppercase',
    fontSize: ({theme}) => theme.fontSizeH1,
    lineHeight: 1
  },
  secondaryId: {
    color: ({theme}) => theme.darkerGrayColor,
    marginTop: '10px',
  },
  collection: {
    margin: '40px 0 20px',
    width: '100%',
  },
  collectionHeading: {
    color: ({theme}) => theme.darkerGrayColor,
  },
  bottomSection: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginTop: '20px'
  },
  productPreorder: {
    marginBottom: '0px',
    height: '20px',
    padding: '20px 0 20px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 2,

    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '50vw',
      background: '#F5F5F5',
      zIndex: 1,
      borderRadius: '20px',
      height: '120px',

      '@media screen and (max-width: 900px)': {
        width: '150vw',
      }
    }
  },
  productPriceSection: {
    marginBottom: '0px',
    height: '80px',
    padding: '20px 0 20px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 6,

    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '50vw',
      background: ({theme}) => theme.mainColor,
      opacity: 0.1,
      zIndex: 5,
      borderRadius: '20px',
      height: '100%',

      '@media screen and (max-width: 900px)': {
        width: '150vw',
      }
    }
  },
  productSaleSection: {
    padding: '15px 0 15px 12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '0px',
    position: 'relative',
    zIndex: 2,
    color: 'white',

    '@media screen and (max-width: 900px)': {
      marginLeft: '0',
      marginRight: '0',
      padding: '15px 15px 15px 15px',
      alignItems: 'flex-start',
      flexDirection: 'column',

      '& div + div': {
        marginTop: '10px',
      }
    },

    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '70vw',
      background: '#202020',
      zIndex: 1,
      borderRadius: '15px',
      height: '100%',

      '@media screen and (max-width: 900px)': {
        width: '150vw',
      }
    }
  },
  productSaleSectionInner: {
    zIndex: 2,
    width: '100%',
  },
  productSaleSectionLeftBlock: {
    zIndex: 2,
    width: '160px',
    maxWidth: '160px',
    minWidth: '160px',
  },
  productSaleSectionRightBlock: {
    zIndex: 2,
  },
  productPrice: {
    fontSize: '28px',
    zIndex: 7,
    fontFamily: ({theme}) => theme.fontFamilyMedium,
  },
  productModSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '28px',
    paddingRight: '84px',
  },
  actions: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
});

const ProductPage = ({ match }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [product, setProduct] = useState({})
  const [currentModification, setCurrentModification] = useState({})
  const [productCount, setProductCount] = useState(1)
  const [loading, setLoading] = useState(true)
  const [alreadyInTheCart, setAlreadyInTheCart] = useState(false)
  const { height, width } = useWindowDimensions();
  const history = useHistory()

  useEffect(() => {
    setProductCount(1)
    if(match.params.product_id && match.params.product_id !== product.id){
      getOne(match.params.product_id).then((res) => {
        setProduct(res.product)
        const currentModId = match.params.mod_id ? match.params.mod_id : res.product.product_modifications[0]?.id
        setCurrentModification(res.product.product_modifications.find((el) => el.id === currentModId))

      }).finally(() => setLoading(false))
    } else if(match.params.mod_id && product.product_modifications) {
      setCurrentModification(product.product_modifications?.find((el) => el.id === match.params.mod_id))
    }
  }, [match])

  useEffect(() => {
    if(currentModification?.id)
      setAlreadyInTheCart(Object.keys(get()).includes(`${product.id}:${currentModification.id}`))
  }, [sessionStorage, currentModification])

  useEffect(() => {
    if(product?.name && product?.product_type) {
      document.title = `${product.name} ${product.product_type} - Christmas products`
    }
  }, [product])

  const addtoCart = () => {
    update(`${product.id}:${currentModification.id}`, productCount)
    setAlreadyInTheCart(true)
  }

  if(loading)
    return (
      <Layout variant="full-page">
        <Loader />
      </Layout>
    )

  return (
    <Layout variant="full-page">
      <div className={classes.root}>
        <div className={classes.leftSide}>
          <div className={classes.mainImage}>
            <img src={product.image_url} />
          </div>
          {width > 1199 && <ProductMainSpecification product={product} />}
        </div>
        <div className={classes.rightSide}>
          <div className={classes.productName}>
            {product.name}
          </div>
          <div className={classes.secondaryId}>
            {product.secondary_id}
          </div>
          {width < 1200 && <ProductMainSpecification product={product} inSection={true} />}
          {
            width > 899 && product.product_collections?.length &&
              <div className={classes.collection}>
                <div className={classes.collectionHeading}>
                  з цієї колекції:
                </div>
                {product.product_collections.map((el) => {
                  return el.id != product.id
                    ? <ProductCollectionItem collectionItem={el} />
                    : null
                })}
              </div>
          }
          <div className={classes.bottomSection}>
            <div className={classes.productModSection}>
              {['ялинка', 'гірлянда'].includes(product.product_type) &&
                <ProductSelect
                  IconComponent={product.product_type === 'ялинка' ? HeightIcon : LengthIcon}
                  values={product.product_modifications}
                  selectedValue={currentModification}
                  coreAttribute='height'
                  navigateToPrepath={`/catalogue/${match.params.product_id}`}
                />
              }
              {['ялинка', 'вінок'].includes(product.product_type) &&
                <ProductSelect
                  IconComponent={product.product_type === 'ялинка' ? WidthIcon : DiameterIcon}
                  values={product.product_modifications}
                  selectedValue={currentModification}
                  coreAttribute='diameter'
                  navigateToPrepath={`/catalogue/${match.params.product_id}`}
                />
              }
            </div>
            <div className={classes.productPriceSection}>
              <div className={classes.productPrice}>
                {Math.ceil(currentModification.price*productCount)} ₴
              </div>
              <ProductCounter productCount={productCount} setProductCount={setProductCount}/>
            </div>
            {product.preorder
              ? <div className={classes.productPreorder}>
                  <span style={{ zIndex: 2 }}>*товар доступний тільки на замовлення</span>
                </div>
              : null
            }

            {/* <div className={classes.productSaleSection}>
              <div className={classes.productSaleSectionInner}>
                <b>Black Friday!</b>
                <br />
                *ціна вказана без врахування знижки
              </div>
              <div className={classes.productSaleSectionLeftBlock}>

              </div>
              <div className={classes.productSaleSectionRightBlock}>
                -5% на все! <br />
                -10% на замовлення від 16 000 грн <br />
                -15% на замовлення від 26 000 грн
              </div>
            </div> */}
            <div className={classes.actions}>
              <Button
                onClick={() => alreadyInTheCart ? history.push('/cart') : addtoCart()}
              >
                {alreadyInTheCart ? 'перейти в корзину' : 'додати в корзину →'}
              </Button>
              {alreadyInTheCart && <CheckIcon />}
            </div>
            {
              width < 900 && product.product_collections?.length &&
                <div className={classes.collection}>
                  <div className={classes.collectionHeading}>
                    з цієї колекції:
                  </div>
                  {product.product_collections.map((el) => {
                    return el.id != product.id
                      ? <ProductCollectionItem collectionItem={el} />
                      : null
                  })}
                </div>
            }
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ProductPage;
