import { apiCallWrapper } from './apiCallWrapper';
import treeTrunkTypeMetalicFurniture from "../assets/images/vz2hs42jl03oy2jc4l8vb4cumt95.jpg";
import treeTrunkTypeMetalicMain from "../assets/images/vvbvjzdi2cbayhp40dl40liztioz.png";
import standTypeMetal from "../assets/images/hevsb41sdlzvv4ejnfwn2iqm66v3.jpg";
import standTypeWood from "../assets/images/cryvoywdwr5py3ub0y4cyl3rta0d.png";
import standTypePlastic from "../assets/images/x6ynkcioplpey6y3zxiegocmvt6a.png";

const prepareId = (id) =>
  id.toUpperCase().replace('А', "A").replace('Н', "H").replace('С', "C")


const stand_type = {
  'metal': { title: "метал", image_url: standTypeMetal },
  'plastic': { title: "пластик", image_url: standTypePlastic },
  'wood': { title: "дерево", image_url: standTypeWood }
}

const material = {
  'plastic_branch_pe': "лита гілка(100%РЕ)",
  'pvc_needles_pvc': "плівка ПВХ",
  'pine_needles_pvc': "жилка ПВХ",
  'pine_needles_film': "плівка + жилка",
  'pe_pvc': "плівка + лита",
}
const tree_trunk_type = {
  "metalic_main": { title: "металева основа", image_url: treeTrunkTypeMetalicMain },
  "metalic_furniture": { title: "металеві шарніри", image_url: treeTrunkTypeMetalicFurniture }
}
const color = {
  'blue': "голубий",
  'green': "зелений",
  'white': "білий",
  'black': "чорний",
}

const category = {
  "xmas_tree": "ялинка",
  "garland": "гірлянда",
  "wreath": "вінок"
}

const prepareProduct = async (product) => {
  const secondary_id = prepareId(product.additional_fields?.secondaryid)

  const image_url = product.additional_fields?.preview_images?.[0]?.url
  const branch_image_url = product.additional_fields?.images?.[0]?.url

  return {
    ...product,
    secondary_id,
    image_url,
    branch_image_url,
    product_type: category[product.additional_fields?.category],
    name: product.title,
    product_color: color[product.additional_fields?.color],
    product_material: material[product.additional_fields?.material],
    snow: !!product.additional_fields?.snow,
    pre_lit: !!product.additional_fields?.led,
    stand_type: stand_type[product.additional_fields?.stand_type],
    tree_trunk_type: tree_trunk_type[product.additional_fields?.tree_trunk_type],
    product_modifications: product.product_modifications?.sort((a,b)=> parseInt(a?.price) > parseInt(b?.price) ? 1 : -1)?.map(pm => ({
      height: pm.additional_fields?.height,
      diameter: pm.additional_fields?.width,
      price: pm.price,
      id: pm.id
    })) || [],
  }
}

const prepareProducts = async (products) =>
  await Promise.all(
    products.filter(product => !product.additional_fields?.hidden_on_site && !!product.additional_fields?.preview_images?.[0])
            .map((product) => prepareProduct(product)))


const filterProducts = (products, searchParams) => {
  const product_type = searchParams.get('product_type')
  const product_color = searchParams.get('product_color')
  const product_material = searchParams.get('product_material')
  const stand_types = searchParams.get('stand_types')
  const tree_trunk_types = searchParams.get('tree_trunk_types')
  const outside = searchParams.get('outside')
  const price = searchParams.get('price')
  const height = searchParams.get('height')
  const diameter = searchParams.get('diameter')
  const searchString = searchParams.get('searchString')

  return products.filter(product =>
    (!searchString || product.title.toLowerCase().includes(searchString.toLowerCase()))
      && (!product_type || product_type.split(",").includes(product.product_type))
      && (!product_color || product_color.split(",").includes(product.product_color))
      && (!product_material || product_material.split(",").includes(product.product_material))
      && (!stand_types || stand_types.split(",").includes(product.stand_type?.title))
      && (!tree_trunk_types || tree_trunk_types.split(",").includes(product.tree_trunk_type?.title))
      && (!outside || outside.split(",").every(key => product[key]))
      && (
        price && price.split(",").length === 2
          ? !!product.product_modifications.filter(pm => parseInt(price.split(",")[0]) <= parseInt(pm?.price) && parseInt(pm?.price) <= parseInt(price.split(",")[1]) ).length
          : true
      ) && (
        height && height.split(",").length === 2
          ? !!product.product_modifications.filter(pm => parseInt(height.split(",")[0]) <= parseInt(pm?.height) && parseInt(pm?.height) <= parseInt(height.split(",")[1]) ).length
          : true
      ) && (
        diameter && diameter.split(",").length === 2
          ? !!product.product_modifications.filter(pm => parseInt(diameter.split(",")[0]) <= parseInt(pm?.diameter) && parseInt(pm?.diameter) <= parseInt(diameter.split(",")[1]) ).length
          : true
      )
  )
}

export const getAll = async (paramsString) => {
  const res = await apiCallWrapper('/products', { method: 'GET' })
  if(res.error)
    throw Error(res.error)

  const products = await prepareProducts(res)

  const result = filterProducts(products, new URLSearchParams(paramsString.substring(1)))

  return { products: result, total: result?.length }
}

export const getOne = async (id) => {
  const res = await apiCallWrapper(`/products/${id}`, { method: 'GET' })
  if(res.error)
    throw Error(res.error)

  const product = await prepareProduct(res)

  return { product }
}
