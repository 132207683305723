import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import LandingApp from './LandingApp';
import './fonts/style.css';

const App = () => {
  return (
    <BrowserRouter>
      <CssBaseline />
      <Switch>
        <Route path='/' component={LandingApp} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
