import treeTrunkTypeMetalicFurniture from "../assets/images/vz2hs42jl03oy2jc4l8vb4cumt95.jpg";
import treeTrunkTypeMetalicMain from "../assets/images/vvbvjzdi2cbayhp40dl40liztioz.png";
import standTypeMetal from "../assets/images/hevsb41sdlzvv4ejnfwn2iqm66v3.jpg";
import standTypeWood from "../assets/images/cryvoywdwr5py3ub0y4cyl3rta0d.png";
import standTypePlastic from "../assets/images/x6ynkcioplpey6y3zxiegocmvt6a.png";

const filterData = {
  "tree_trunk_types": [
    {
      "id": 2,
      "title": "металева основа",
      "image_url": treeTrunkTypeMetalicMain
    },
    {
      "id": 1,
      "title": "металеві шарніри",
      "image_url": treeTrunkTypeMetalicFurniture
    }
  ],
  "stand_types": [
    {
      "id": 1,
      "title": "метал",
      "image_url": standTypeMetal
    },
    {
      "id": 2,
      "title": "дерево",
      "image_url": standTypeWood
    },
    {
      "id": 3,
      "title": "пластик",
      "image_url": standTypePlastic
    }
  ],
  "product_material": [
    {
      "id": 1,
      "title": "лита гілка(100%РЕ)",
      "image_url": null
    },
    {
      "id": 2,
      "title": "жилка ПВХ",
      "image_url": null
    },
    {
      "id": 3,
      "title": "плівка ПВХ",
      "image_url": null
    },
    {
      "id": 4,
      "title": "плівка + жилка",
      "image_url": null
    },
    {
      "id": 5,
      "title": "плівка + лита",
      "image_url": null
    }
  ],
  "product_color": [
    {
      "id": 1,
      "title": "голубий",
      "image_url": null
    },
    {
      "id": 3,
      "title": "зелений",
      "image_url": null
    },
    {
      "id": 4,
      "title": "білий",
      "image_url": null
    },
    {
      "id": 5,
      "title": "чорний",
      "image_url": null
    }
  ],
  "product_height": [
    0,
    500
  ],
  "product_price": [
    "100",
    "70000"
  ],
  "product_diameter": [
    0,
    400
  ]
}

export const getFilterData = () => {
  return Promise.resolve(filterData);
}
