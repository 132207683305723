import React from 'react';
import { createUseStyles, useTheme } from "react-jss";

const sizeVariantMap = {
  'default' : {
    width: '200px',
    height: '40px',
  }
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: ({ theme }) => theme.mainColor,
    color: ({ theme }) => theme.whiteColor,
    fontFamily: ({ theme }) => theme.fontFamilyMedium,
    fontSize: ({ theme }) => theme.fontSizeBody,
    borderRadius: '4px',
    border: 0,
    cursor: 'pointer',
    width: ({ variant }) => sizeVariantMap[variant]?.width,
    height: ({ variant }) => sizeVariantMap[variant]?.height,

    '&:disabled': {
      background: '#bbb',
      pointerEvents: 'none',
    }
  },
});

const Button = ({children, onClick, disabled = false, variant = 'default'}) => {
  const theme = useTheme();
  const classes = useStyles({ theme, variant });

  return (
    <button
      className={classes.root}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{children}</span>
    </button>
  );
}

export default Button;
