import React, {useMemo} from 'react';
import { createUseStyles, useTheme } from "react-jss";
import { useHistory } from 'react-router';

const useStyles = createUseStyles({
  collectionItem: {
    cursor: 'pointer',
    padding: '7px 12px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
    borderRadius: '20px',

    '&:hover': {
      background: ({theme}) => theme.grayColor,
      color: ({theme}) => theme.mainColor,
    }
  },
  collectionItemImage: {
    borderRadius: '50%',
    height: '66px',
    width: '66px',
    overflow: 'hidden',
    boxShadow: '2px 4px 15px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    alignItems: 'center',
    background: ({theme}) => theme.whiteColor,

    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
      height: 'auto',
      width: 'auto',
    },
  },
  collectionItemTitle: {
    flex: '1 1 auto',
    paddingLeft: '20px'
  },
  collectionItemPrice: {
    fontFamily: ({theme}) => theme.fontFamilyBold,
  },
});

const ProductCollectionItem = ({ collectionItem }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();

  const prices = useMemo(() => {
    const pmods = collectionItem.product_modifications.sort((a,b)=> a?.price > b?.price)
    if(pmods.length > 1){
      return `${Math.ceil(pmods[0].price)}₴ - ${Math.ceil(pmods[pmods.length - 1].price)}₴`
    } else {
      return `${Math.ceil(pmods[0].price)}₴`
    }
  }, [collectionItem])

  const navigateToProduct = (id) => (e) => {
    history.push(`/catalogue/${id}`)
  }

  return (
    <div className={classes.collectionItem} onClick={navigateToProduct(collectionItem.id)}>
      <div className={classes.collectionItemImage}>
        <img src={collectionItem.image_url} />
      </div>
      <div className={classes.collectionItemTitle}>
        {collectionItem.name}
      </div>
      <div className={classes.collectionItemPrice}>
        {prices}
      </div>
    </div>
  );
}

export default ProductCollectionItem;
