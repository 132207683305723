import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from "react-jss";
import { getFilterData } from '../api/Filter';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import Slider from '../components/Slider';
import filterTreeImg from '../assets/filterTree.png';
import filterBranchImg from '../assets/filterBranch.png';
import filterRingImg from '../assets/filterRing.png';
import Button from '../components/Button';
import { useHistory } from 'react-router';
import clsx from 'clsx';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      height: 'auto',
    },
  },
  sections: {
    display: 'flex',
    flex: '1 1 auto',
    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      flexDirection: 'column'
    },
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  section: {
    '& + &': {
      marginLeft: '40px',

      '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
        marginTop: '40px',
        marginLeft: '0',
      },
    }
  },
  sectionFullWidth: {
    flex: '1 1 auto',

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      height: 'auto',
      marginBottom: '30px',
    },
  },
  sectionLabel: {
    marginBottom: '30px',
  },
  sectionItems: {
    display: 'flex',
    flexDirection: 'column',

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      flexDirection: 'row'
    },

    '@media screen and (max-width: 900px)': {
      flexDirection: 'column',
    }
  },
  sectionItemsLine: {
    display: 'flex',
    flexDirection: 'row',
  },
  sectionItemsRightAligned: {
    alignItems: 'flex-end',
  },
  sectionItem: {
    display: 'flex',
    border: ({theme}) => `1px solid ${theme.grayColor}`,
    borderRadius: '10px',
    padding: '15px',
    alignItems: 'center',
    width: '180px',
    maxHeight: '80px',
    cursor: 'pointer',

    '& + &': {
      marginTop: '20px',

      '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
        marginTop: '0',
        marginLeft: '20px',
      },

      '@media screen and (max-width: 900px)': {
        marginTop: '20px',
        marginLeft: '0px',
      }
    },

    '&:hover:not(.-selected)': {
      background: ({theme}) => theme.grayColor,
    },

    '&.-selected': {
      background: ({theme}) => `${theme.mainColor}4D`,
    }
  },
  sectionItemTextOnly: {
    padding: '6px 15px',

    '& + &': {
      marginTop: '8px'
    }
  },
  sectionItemAutoWidth: {
    width: 'auto'
  },
  sectionItemImg: {
    height: '50px',
    minHeight: '50px',
    maxHeight: '50px',
    width: '50px',
    minWidth: '50px',
    maxWidth: '50px',
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: '9px',
    display: 'flex',
    alignItems: 'center',
    background: ({theme}) => theme.whiteColor,

    '& img': {
      maxWidth: '100%',
    }
  },
  sectionItemTitle: {
  },
  sectionItemColor: {
    height: '30px',
    width: '30px',

    '& + &': {
      marginLeft: '5px',
      marginTop: '0'
    },

    '&.-selected': {
      borderColor: ({theme}) => theme.mainColor,
      outline: ({theme}) => `5px solid ${theme.mainColor}4D`
    }
  },
});

const FiltersPage = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [loading, setLoading] = useState(true)
  const [currentSlider, setCurrentSlider] = useState('price')
  const [filters, setFilters] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const history = useHistory()

  useEffect(() => {
    document.title = 'Фільтр - Christmas products'
    setLoading(true)
    getFilterData().then((res) => {
      const currFilters = res
      currFilters['product_type'] = [
        {id: 'ялинка', title: 'ялинка', image_url: filterTreeImg},
        {id: 'гірлянда', title: 'гірлянда', image_url: filterBranchImg},
        {id: 'вінок', title: 'вінок', image_url: filterRingImg},
      ]
      currFilters['outside'] = [
        { id: 'snow', title: 'засніження' },
        { id: 'pre_lit', title: 'гірлянда' }
      ]
      currFilters['product_color'] = res.product_color.map((el) => {
        let hex = '#fff'
        switch (el.title) {
          case 'зелений':
            hex = '#48743D';
            break;
          case 'голубий':
            hex = '#3FA7BE';
            break;
          case 'чорний':
            hex = '#000000';
            break;
          default:
            hex = '#ffffff';
            break;
        }
        el.hex = hex;
        return el;
      })
      setFilters(currFilters)
      const sessionFilters = JSON.parse(sessionStorage.getItem('filters') || '{}')
      if(Object.keys(sessionFilters).length) {
        setSelectedValues(sessionFilters)
      } else {
        setSelectedValues({
          price: currFilters.product_price.map((el) => Math.ceil(el)),
          diameter: currFilters.product_diameter.map((el) => Math.ceil(el)),
          height: currFilters.product_height.map((el) => Math.ceil(el)),
        })
      }
      setLoading(false)
    })
  }, [])

  const filterOnClick = (label, id) => (e) => {
    let newValue = selectedValues[label]?.length ? [...selectedValues[label], id] : [id]
    if(filterAlreadySet(label, id)) {
      newValue = selectedValues[label].filter((el) => el != id)
      if(!newValue.length) {
        const newObj = { ...selectedValues }
        delete newObj[label]
        setSelectedValues(newObj)
        return;
      }
    }

    setSelectedValues({ ...selectedValues, [label]: newValue })
  }

  const selectedClassName = (label, id) => (
    selectedValues[label]?.includes(id) ? '-selected' : ''
  )

  const filterAlreadySet = (label, id) => (
    selectedValues[label]?.includes(id)
  )

  const filterOnSubmit = () => {
    sessionStorage.setItem('filters', JSON.stringify(selectedValues))
    history.push(`/catalogue?${new URLSearchParams(selectedValues).toString()}`)
  }


  if(loading || !Object.keys(filters)?.length)
    return (
      <Layout>
        <Loader />
      </Layout>
    )

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.sections}>
          <div className={classes.section}>
            <div className={classes.sectionLabel}>
              Тип товару:
            </div>
            <div className={classes.sectionItems}>
              {filters.product_type?.map((el) =>
                <div
                  className={clsx(classes.sectionItem, selectedClassName('product_type', el.title))}
                  onClick={filterOnClick('product_type', el.title)}
                  key={el.title}
                >
                  <div className={classes.sectionItemImg}>
                    <img src={el.image_url} />
                  </div>
                  <div className={classes.sectionItemTitle}>
                    {el.title}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionLabel}>
              Колір виробу:
            </div>
            <div className={clsx(classes.sectionItems,classes.sectionItemsLine)}>
              {filters.product_color?.map((el) =>
                <div
                  className={clsx(classes.sectionItem, classes.sectionItemColor, selectedClassName('product_color', el.title))}
                  onClick={filterOnClick('product_color', el.title)}
                  style={{ backgroundColor: el.hex }}
                  key={el.title}
                />
              )}
            </div>
            <br />
            <br />
            <div className={classes.sectionLabel}>
              Матеріал:
            </div>
            <div className={classes.sectionItems}>
              {filters.product_material?.map((el) =>
                <div
                  className={clsx(classes.sectionItem, classes.sectionItemTextOnly, selectedClassName('product_material', el.title))}
                  onClick={filterOnClick('product_material', el.title)}
                  key={el.title}
                >
                  <div className={classes.sectionItemTitle}>
                    {el.title}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionLabel}>
              Тип підставки:
            </div>
            <div className={classes.sectionItems}>
              {filters.stand_types?.map((el) =>
                <div
                  className={clsx(classes.sectionItem, selectedClassName('stand_types', el.title))}
                  onClick={filterOnClick('stand_types', el.title)}
                  key={el.title}
                >
                  <div className={classes.sectionItemImg}>
                    <img src={el.image_url} />
                  </div>
                  <div className={classes.sectionItemTitle}>
                    {el.title}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionLabel}>
              Тип стовбуру:
            </div>
            <div className={classes.sectionItems}>
              {filters.tree_trunk_types?.map((el) =>
                <div
                  className={clsx(classes.sectionItem, selectedClassName('tree_trunk_types', el.title))}
                  onClick={filterOnClick('tree_trunk_types', el.title)}
                  key={el.title}
                >
                  <div className={classes.sectionItemImg}>
                    <img src={el.image_url} />
                  </div>
                  <div className={classes.sectionItemTitle}>
                    {el.title}
                  </div>
                </div>
              )}
            </div>
            <br />
            <br />
            <div className={classes.sectionLabel}>
              Зовнішнє оформлення:
            </div>
            <div className={classes.sectionItems}>
              {filters.outside?.map((el) =>
                <div
                  className={clsx(classes.sectionItem, classes.sectionItemTextOnly, selectedClassName('outside', el.id))}
                  onClick={filterOnClick('outside', el.id)}
                  key={el.id}
                >
                  <div className={classes.sectionItemTitle}>
                    {el.title}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={clsx(classes.section, classes.sectionFullWidth)}>
            <Slider
              minValue={Math.ceil(filters[`product_${currentSlider}`][0])}
              maxValue={Math.ceil(filters[`product_${currentSlider}`][1])}
              valuePair={selectedValues[currentSlider]}
              valueOnChange={(e, newV) => setSelectedValues({ ...selectedValues, [currentSlider]: newV})}
              label={currentSlider === 'price' ? 'грн' : 'см'}
            >
              <div className={clsx(classes.sectionItems, classes.sectionItemsRightAligned)}>
                <div
                  className={clsx(classes.sectionItem, classes.sectionItemTextOnly, classes.sectionItemAutoWidth, currentSlider == 'price' ? '-selected' : '')}
                  onClick={() => setCurrentSlider('price')}
                >
                  <div className={classes.sectionItemTitle}>
                    Ціна (грн)
                  </div>
                </div>
                <div
                  className={clsx(classes.sectionItem, classes.sectionItemTextOnly, classes.sectionItemAutoWidth, currentSlider == 'diameter' ? '-selected' : '')}
                  onClick={() => setCurrentSlider('diameter')}
                >
                  <div className={classes.sectionItemTitle}>
                    Діаметр (см)
                  </div>
                </div>
                <div
                  className={clsx(classes.sectionItem, classes.sectionItemTextOnly, classes.sectionItemAutoWidth, currentSlider == 'height' ? '-selected' : '')}
                  onClick={() => setCurrentSlider('height')}
                >
                  <div className={classes.sectionItemTitle}>
                    Висота (см)
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className={classes.submitButton}>
          <Button onClick={filterOnSubmit}>застосувати</Button>
        </div>
      </div>
    </Layout>
  );
}

export default FiltersPage;
