import React from 'react';
import { createUseStyles, useTheme } from "react-jss";
import { useHistory } from 'react-router';

const useStyles = createUseStyles({
  slider: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '90%',
  },
  heading: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    fontSize: ({ theme }) => theme.fontSizeH1,
  },
  text: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    color: ({ theme }) => theme.textColor,
    margin: '20px 0',
    maxWidth: '530px',
    minHeight: '100px'
  },
  link: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    color: ({ theme }) => theme.mainColor,
    textDecoration: 'none',
    alignSelf: 'flex-end',
  },
  numbers: {
    display: 'flex',
  },
  numbersItem: {
    '& + &': {
      marginLeft: '75px',
    }
  },
  numbersItemNum: {
    color: ({ theme }) => theme.mainColor,
    fontSize: '50px',
  },
  numbersItemText: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    color: ({ theme }) => theme.textColor,
  },
  images: {
    margin: '30px 0 50px',

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      display: 'flex',
      overflow: 'hidden'
    },

    '@media screen and (max-width: 900px)': {
      overflowX: 'auto'
    },

    '& img + img': {
      marginLeft: '30px',
    }
  },
});

const InfoSlide = ({active = false, heading, text, link, numbers = false, images}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory()

  if(!active)
    return null;

  return (
    <div className={classes.slider}>
      <div className={classes.heading}>
        {heading}
      </div>
      <div className={classes.text}>
        {text}
      </div>
      {link
        ? <a className={classes.link} href={link} onClick={(e) => { e.preventDefault(); history.push(link) }}>
            Перейти до каталогу →
          </a>
        : null
      }
      {numbers.length > 0
        ? <div className={classes.numbers}>
          <div className={classes.numbersItem}>
            <div className={classes.numbersItemNum}>
              {numbers[0][0]}
            </div>
            <div className={classes.numbersItemText}>
              {numbers[0][1]}
            </div>
          </div>
          <div className={classes.numbersItem}>
            <div className={classes.numbersItemNum}>
              {numbers[1][0]}
            </div>
            <div className={classes.numbersItemText}>
              {numbers[1][1]}
            </div>
          </div>
        </div>
        : null
      }
      <div className={classes.images}>
        {images}
      </div>
    </div>
  );
}

export default InfoSlide;
