import React from 'react';
import { createUseStyles, useTheme } from "react-jss";


const useStyles = createUseStyles({
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: ({theme}) => theme.fontFamilyBold,
    color: ({theme}) => theme.grayColor,
    fontSize: '80px',
    textAlign: 'center',
    '@media screen and (max-width: 1200px)': {
      fontSize: '40px',
    },
    '@media screen and (max-width: 900px)': {
      fontSize: '40px',
    },
  },
  rootInner: {
    '& div': {
      color: ({theme}) => theme.textColor,
      fontSize: '40px',
      lineHeight: .8,
      opacity: .8,

      '@media screen and (max-width: 900px)': {
        fontSize: '30px',
        lineHeight: 1,
      },
    },
    '& span': {
      color: ({theme}) => theme.mainColor,
      fontSize: '20px',
      display: 'block',
      marginTop: '50px',

      '@media screen and (max-width: 900px)': {
        fontSize: '20px',
        lineHeight: 1,
      },
    }
  }
});

const Empty = ({children}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.root}>
      <div className={classes.rootInner}>
        {children}
      </div>
    </div>
  );
}

export default Empty;
