import { apiCallWrapper } from './apiCallWrapper';

export const create = (details, order_items_attributes) => {
  return apiCallWrapper(`/orders`, {
    method: 'POST',
    body: JSON.stringify({
      order: {
        source: 'christmas-products.com.ua',
        ...details,
        order_items_attributes
      }
    })
  })
}
