/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import Empty from '../components/Empty';

const CartSubmittedPage = () => {

  useEffect(() => {
    document.title = 'Замовлення успішно оформлено - Christmas products'
  }, [])

  return (
    <Layout>
      <Empty><div>Дякуємо за замовлення!</div><span>Наш менеджер зв'яжеться з вами для уточнення деталей</span></Empty>
    </Layout>
  );
}

export default CartSubmittedPage;
