import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from "react-jss";
import Layout from '../components/Layout';
import { ReactComponent as CheckIcon } from '../assets/check.svg';
import { ReactComponent as AccordionArrowIcon } from '../assets/accordionArrow.svg';
import coop1 from '../assets/coop1.png';
import coop2 from '../assets/coop2.png';
import coop3 from '../assets/coop3.png';
import coop4 from '../assets/coop4.png';
import coop5 from '../assets/coop5.png';
import coop6 from '../assets/coop6.png';
import coop7 from '../assets/coop7.png';
import clsx from 'clsx';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useHistory } from 'react-router';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    marginRight: '-20px',
    paddingRight: '20px',
  },
  textBlock: {
    marginBottom: '20px',
  },
  text: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    color: ({ theme }) => theme.textColor,
  },
  infoBlock: {
    marginTop: '10px',
    padding: '27px 30px',
    borderRadius: '0 10px 10px 0',
    background: ({ theme }) => `${theme.grayColor}4D`,
    borderLeft: ({ theme }) => `2px solid ${theme.mainColor}`,
  },
  infoBlockText: {
    maxWidth: '810px',
    '& + &': {
      marginTop: '10px',
    }
  },
  infoBlockLink: {
    color: ({ theme }) => theme.mainColor,
    cursor: 'pointer'
  },
  checkItem: {
    '& + &': {
      marginTop: '15px',
    }
  },
  checkItemHeading: {
    fontFamily: ({ theme }) => theme.fontFamilyBold,
    color: ({ theme }) => theme.textColor,
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: '5px',
    }
  },
  checkItemText: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    color: ({ theme }) => theme.textColor,
  },
  accodrionBlock: {
    marginTop: '8px',

    '& + &': {
      marginTop: '20px',
    }
  },
  accordionHeading: {
    fontFamily: ({ theme }) => theme.fontFamilyBold,
    color: ({ theme }) => theme.textColor,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& svg': {
      marginLeft: '10px',
    },

    '&.-active path': {
        fill: ({ theme }) => theme.mainColor,
    },

    '&.-active svg': {
      transform: 'rotate(180deg)',
    }
  },
  footerTextBlock: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    color: ({ theme }) => theme.textColor,
    maxWidth: '800px',
    marginTop: '28px',
  },
  gridTable: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    background: ({ theme }) => `${theme.grayColor}4D`,
    marginTop: '8px',

    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  gridTableItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 20px',
    borderRadius: '10px',
  },
  gridTableItemRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderRadius: '10px',
    padding: '15px 20px',

    '& a': {
      color: ({ theme }) => theme.textColor,
    },

    '& a:hover': {
      color: ({ theme }) => theme.mainColor,
    }
  },
  gridTableItemEven: {
    background: ({ theme }) => `${theme.grayColor}99`,
  },
  tableImg: {
    height: '70px',
    width: '70px',
    borderRadius: '50%',
    backgroundSize: 'contain',
    backgroundColor: ({ theme }) => theme.whiteColor,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginLeft: '30px',
  }
});

const prices = [
  { image: coop1, title: 'Ялинки 100% PE (лита гілка)', link: 'https://docs.google.com/spreadsheets/d/1FvBdHghFt6JgAkOWEPdd8Pp8razv6fU8/edit?usp=sharing&ouid=114763857815977264025&rtpof=true&sd=true' },
  { image: coop2, title: 'Ялинки на металевих шарнірах', link: 'https://docs.google.com/spreadsheets/d/1Dg1cDDNJAYHthTSvPMU5YOLfGH7vJlmQ/edit?usp=sharing&ouid=114763857815977264025&rtpof=true&sd=true' },
  { image: coop3, title: 'Комбіновані ялинки', link: 'https://docs.google.com/spreadsheets/d/1ak-5L7xjWF0oPL8icdaTs9axAAZcylFO/edit?usp=sharing&ouid=114763857815977264025&rtpof=true&sd=true' },
  { image: coop4, title: 'Ялинки класік', link: 'https://docs.google.com/spreadsheets/d/1LtRL8KdjCclyzZLqs_pJolf84kPrfZiC/edit?usp=sharing&ouid=114763857815977264025&rtpof=true&sd=true' },
  { image: coop5, title: 'Вінки та гірлянди', link: 'https://docs.google.com/spreadsheets/d/1MAum_F5Hkmdd5AWFgXJ_hBlsf7J4vTln/edit?usp=sharing&ouid=114763857815977264025&rtpof=true&sd=true' },
  { image: coop6, title: 'Литі гілочки', link: 'https://docs.google.com/spreadsheets/d/17GBZmzwqB1aKUwboil7Lg2qY9E_yEvi5/edit?usp=sharing&ouid=114763857815977264025&rtpof=true&sd=true' },
  { image: coop7, title: 'Вуличні ялинки', link: 'https://docs.google.com/spreadsheets/d/1a9VZB0fMR3gw-I_Cv-XLMO192Lh07KXK/edit?usp=sharing&ouid=114763857815977264025&rtpof=true&sd=true' },
]

const CooperationPage = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [pricesOpened, setPricesOpened] = useState(false)
  const [dropOpened, setDropOpened] = useState(false)
  const [optOpened, setOptOpened] = useState(false)
  const { width } = useWindowDimensions()
  const history = useHistory()

  useEffect(() => {
    document.title = 'Співпраця - Christmas products'
  }, [])

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.textBlock}>
          <div className={classes.text}>
            Ми дуже раді кожному партнеру, та пропонуємо найкращі умови для нашої комфортної співпраці.
          </div>
        </div>
        <div className={classes.textBlock}>
          <div className={classes.text}>
            Переваги для вас:
          </div>
          <div className={classes.infoBlock}>
            <div className={classes.checkItem}>
              <div className={classes.checkItemHeading}>
                <CheckIcon /> Кращі товари
              </div>
              <div className={classes.checkItemText}>
                Вся  продукція являється сертифікованою і ми дуже багато уваги приділяємо якості
              </div>
            </div>
            <div className={classes.checkItem}>
              <div className={classes.checkItemHeading}>
                <CheckIcon /> Великий асортимент
              </div>
              <div className={classes.checkItemText}>
                Асортимент новорічної породукції оновлюється кожного року (як мінімум це +5 нових товарів)
              </div>
            </div>
            <div className={classes.checkItem}>
              <div className={classes.checkItemHeading}>
                <CheckIcon /> Зручність
              </div>
              <div className={classes.checkItemText}>
                Склади виробника знаходиться на території України, що забезпечує швидку доставку по всій країні.
              </div>
            </div>
          </div>
        </div>
        {/* <div className={classes.accodrionBlock}>
          <div className={clsx(classes.accordionHeading, pricesOpened ? '-active' : '')} onClick={() => setPricesOpened(!pricesOpened)}>
            Дивитись прайси <AccordionArrowIcon />
          </div>
          {pricesOpened ?
            <div className={classes.accordionContent}>
              <div className={classes.gridTable}>
                {width > 899 && <div className={classes.gridTableItem}>
                </div>}
                <div className={classes.gridTableItem}>
                  назва
                </div>
                <div className={classes.gridTableItemRight}>
                  посилання
                </div>

                {prices.map((item, i) => (
                  <>
                    {width > 899 && <div className={clsx(classes.gridTableItem, i%2 == 0 ? classes.gridTableItemEven : '')}>
                      <div className={classes.tableImg} style={{backgroundImage: `url('${item.image}')`}}></div>
                    </div>}
                    <div className={clsx(classes.gridTableItem, i%2 == 0 ? classes.gridTableItemEven : '')}>
                      {item.title}
                    </div>
                    <div className={clsx(classes.gridTableItemRight, i%2 == 0 ? classes.gridTableItemEven : '')}>
                      <a href={item.link} target="_blank">Посилання на Google drive</a>
                    </div>
                  </>
                ))}
              </div>
            </div>
            : null
          }
        </div> */}
        <div className={classes.accodrionBlock}>
          <div className={clsx(classes.accordionHeading, dropOpened ? '-active' : '')} onClick={() => setDropOpened(!dropOpened)}>
            Дропшипінг <AccordionArrowIcon />
          </div>
          {dropOpened ?
            <div className={classes.accordionContent}>
              <div className={classes.infoBlock}>
                <div className={classes.infoBlockText}>
                  Наш асортимент ви можете бачити  на сайті або завантажити прайс листи. Ми надаємо фото всіх товарів та сертифікатів якості, вам залишається лише почати співпрацю. Для цього необхідно зв'язантись з нами
                  (сторінка <a className={classes.infoBlockLink} onClick={(e) => {e.preventDefault(); history.push('/contacts')}}>контакти</a>).
                  Після затвердження менеджером вашої заявки ви отримаєте пакет у який входить фото товарів та прайс для дропшиперів.
                </div>
                {/* <a className={classes.infoBlockLink} href='/322'>Зареєструватись як дропшипер</a> */}
              </div>
            </div>
            : null
          }
        </div>
        {/* <div className={classes.accodrionBlock}>
          <div className={clsx(classes.accordionHeading, optOpened ? '-active' : '')} onClick={() => setOptOpened(!optOpened)}>
            Гурт(опт) <AccordionArrowIcon />
          </div>
          {optOpened ?
            <div className={classes.accordionContent}>
              <div className={classes.infoBlock}>
                <div className={classes.infoBlockText}>
                  Для оптових клієнтів ми пропонуємо такі знижки
                </div>
                <div className={classes.infoBlockText}>
                  замовлення на суму від 1000$ - 5%
                  <br/>
                  замовлення на суму від 2000$ - 10%
                  <br/>
                  замовлення на суму від 3000$ - 15%
                </div>
                <div className={classes.infoBlockText}>
                  Ми надаємо знижки посезонно, а також від суми замовлення.
                  <br />
                  За детальною інформацією звертайтеся до менеджерів, які нададуть вам прайси з оптовими цінами та люб’язно розкажуть про умови співпраці.
                </div>
              </div>
            </div>
            : null
          }
        </div> */}
        <div className={classes.footerTextBlock}>
          Обмін/повернення можливий протягом 14 днів, після отримання посилки.
          <br/>
          Обмін товару належної якості провадиться, якщо він не використовувався і якщо збережено його товарний вигляд, споживчі властивості, пломби, ярлики.
          <br/>
          Якщо клієнтові не підійшли розміри або колір - доставка за рахунок КЛІЄНТА.
          <br/>
          Якщо клієнту прийшов товар з невірними розмірами, кольром, виробничим браком  - доставка за наш рахунок.
        </div>

      </div>
    </Layout>
  );
}

export default CooperationPage;
