import React, { useEffect } from 'react';
import Landing from './pages/Landing';
import { ThemeProvider } from "react-jss";
import { CssBaseline } from '@material-ui/core';
import { useLocation } from 'react-router';

const theme = {
  whiteColor: "#ffffff",
  textColor: "#454545",
  mainColor: "#8D71DC",
  grayColor: "#f5f5f5",
  darkerGrayColor: "#757575",
  lightGrayColor: "#CDCDCD",
  fontFamilyDefault: 'Geometria',
  fontFamilyThin: 'Geometria-Thin',
  fontFamilyLight: 'Geometria-Light',
  fontFamilyMedium: 'Geometria-Medium',
  fontFamilyBold: 'Geometria-Bold',
  fontSizeH1: '24px',
  fontSizeH2: '18px',
  fontSizeH3: '14px',
  fontSizeBody: '14px',
};

const LandingApp = () => {
  const location = useLocation()

  useEffect(() => {
    const referrer = (new URLSearchParams(location.search)).get('r');
    if(referrer)
      localStorage.setItem('referrer', referrer)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Landing />
    </ThemeProvider>
  );
}

export default LandingApp;
