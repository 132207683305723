export const get = () => {
  return JSON.parse(sessionStorage.getItem('cart') || '{}')
}
export const update = (add_id, add_count, remove_id) => {
  let currentCart = get();

  if(add_id)
    currentCart[`${add_id}`] = add_count

  if(remove_id)
    delete currentCart[`${remove_id}`]

  sessionStorage.setItem('cart', JSON.stringify(currentCart))
  return get();
}

export const clear = () => {
  sessionStorage.setItem('cart', '{}')
  return get();
}
