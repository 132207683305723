import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import MainPage from './MainPage';
import { createUseStyles, useTheme } from "react-jss";
import Navigation from '../components/Navigation';
import InfoPage from './InfoPage';
import ContactsPage from './ContactsPage';
import CooperationPage from './CooperationPage';
import CataloguePage from './CataloguePage';
import ProductPage from './ProductPage';
import FiltersPage from './FiltersPage';
import CartPage from './CartPage';
import CartSubmittedPage from './CartSubmittedPage';
import ReactGA from 'react-ga4';

const useStyles = createUseStyles({
  root: {
    background: ({ theme }) => theme.whiteColor,
    position: 'relative',
    minHeight: '100vh',
    minWidth: '100vw',
    overflow: 'hidden',
  },
  title: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    color: ({ theme }) => theme.textColor
  },
});

const Landing = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const location = useLocation();

  useEffect(() => {
    ReactGA.send("pageview");
  }, [location])

  const onClickToCloseOpenedContainers = (event) => {
    if (!event.target.closest('.-opened')) {
      if(document.getElementsByClassName('-opened')[0])
        document.getElementsByClassName('-opened')[0].classList.remove("-opened")
    }
  }

  return (
    <div className={classes.root} onClick={onClickToCloseOpenedContainers}>
      <Navigation />
      <Switch>
        <Route path='/' exact component={MainPage} />
        <Route path='/info' exact component={InfoPage} />
        <Route path='/contacts' exact component={ContactsPage} />
        <Route path='/cooperation' exact component={CooperationPage} />
        <Route path='/catalogue' exact component={CataloguePage} />
        <Route path='/catalogue/:product_id/:mod_id?' exact component={ProductPage} />
        <Route path='/filter' exact component={FiltersPage} />
        <Route path='/cart' exact component={CartPage} />
        <Route path='/cart-submitted' exact component={CartSubmittedPage} />
      </Switch>
    </div>
  );
}

export default Landing;
