import React, { useState, useEffect } from 'react';
import { createUseStyles, useTheme } from "react-jss";
import { ReactComponent as MenuClosedIcon } from '../assets/menu.svg';
import { ReactComponent as MenuOpenedIcon } from '../assets/menuOpened.svg';
import { ReactComponent as SearchIcon } from '../assets/search.svg';
import { ReactComponent as CatalogueIcon } from '../assets/catalogue.svg';
import { ReactComponent as FilterIcon } from '../assets/filter.svg';
import { ReactComponent as CartIcon } from '../assets/cart.svg';
import { ReactComponent as InformationIcon } from '../assets/information.svg';
import { ReactComponent as CooperationIcon } from '../assets/cooperation.svg';
import { ReactComponent as ContactsIcon } from '../assets/contacts.svg';
import { ReactComponent as ArrowRight } from '../assets/arrowRight.svg';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router';
import useWindowDimensions from '../hooks/useWindowDimensions';


const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    top: 0,
    right: '750px',
    height: '100vh',
    zIndex: 10,
    width: '100vw',
    transform: "translate(-50%, 0)",
    '@media screen and (max-width: 1800px)': {
      right: '650px',
    },

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      position: 'fixed',
      right: 'auto',
      width: ({ expanded }) => expanded ? '205px' : '80px',
      transform: "none",
      left: 0
    },
  },
  opener: {
    background: ({ theme }) => theme.mainColor,
    width: '80px',
    height: '80px',
    borderRadius: '0 0 30px 30px',
    position: 'absolute',
    top: '-10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      left: 0,
      right: 'auto'
    },
    zIndex: 10,
    cursor: 'pointer',
  },
  body: {
    height: '100%',
    width: '100%',
    background: ({ theme }) => theme.whiteColor,
    right: ({ expanded }) => expanded ? '-125px' : '0px',
    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      left: 0,
      right: 'auto'
    },
    zIndex: 9,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: '0 50px 50px 0',
    boxShadow: '2px 4px 15px rgba(0, 0, 0, 0.05)',
    top: ({ opened }) => (opened ? '0' : '-100vh'),
    overflow: 'hidden',
  },
  navItems: {
  },
  navItem: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    display: 'flex',
    alignItems: 'center',
    width: ({ expanded }) => expanded ? '205px' : '80px',
    position: 'relative',
    height: '60px',
    cursor: 'pointer',

    '&:hover': {
      '&:before': {
        display: 'block'
      }
    },

    '&:before': {
      display: 'none',
      position: 'absolute',
      top: 0,
      zIndex: -1,
      right: ({ expanded }) => expanded ? '20px' : '0',
      content: '""',
      borderRadius: '0 5px 5px 0',
      width: '600px',
      height: '60px',
      backgroundColor: ({theme}) => theme.grayColor,
    },

    '&:after': {
      display: 'none',
      position: 'absolute',
      top: 0,
      zIndex: -1,
      right: '0',
      content: '""',
      borderRadius: '1px',
      width: '2px',
      height: '60px',
      backgroundColor: ({theme}) => theme.mainColor,
    },

    '&.-active': {
      '& path': {
        fill: ({theme}) => theme.mainColor,
      },
      '&:after': {
        display: 'block'
      },
      '&:before': {
        display: 'block'
      }
    },
  },
  navItemIcon: {
    margin: '0 30px',
    display: 'inline-flex',
  },
  dirty: {
    position: 'relative',

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: '0',
      top: '0',
      height: '10px',
      width: '10px',
      background: ({theme}) => theme.mainColor,
      border: ({theme}) => `2px solid ${theme.whiteColor}`,
      borderRadius: '50%',
    }
  },
  navItemText: {
    display: 'flex',
    alignItems: 'center'
  },
  navItemInput: {
    border: ({theme}) => `1px solid ${theme.grayColor}`,
    borderRadius: '10px',
    height: '30px',
    maxWidth: '100px',
    padding: '8px 14px',
    marginLeft: '-14px',

    '&:focus': {
      outline: 0
    }
  },
  navItemButton: {
    marginLeft: '-15px',
    marginTop: '3px'
  },
  overlay: {
    display: ({ opened }) => opened ? 'block' : 'none',

    '@media screen and (max-width: 900px)': {
      background: ({ theme }) => `${theme.darkerGrayColor}4D`,
      width: '100vw',
      height: '100vh',
    }
  }
});

const Navigation = ({}) => {
  const theme = useTheme();
  const { height, width } = useWindowDimensions();
  const [opened, setOpened] = useState(width > 900);
  const [expanded, setExpanded] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [filtersDirty, setFiltersDirty] = useState(false);
  const [cartDirty, setCartDirty] = useState(false);
  const location = useLocation();
  const history = useHistory()
  const classes = useStyles({ theme, opened, expanded });

  const onClick = (path) => () => {
    history.push(path)
    width < 900 && setOpened(false)
  }

  useEffect(() => {
    width < 900 && setExpanded(opened)
  }, [opened])

  const triggerSearch = () => {
    setSearchString('')
    history.push(`/catalogue?searchString=${encodeURIComponent(searchString)}`)
  }

  useEffect(() => {
    setFiltersDirty(!!Object.keys(JSON.parse(sessionStorage.getItem('filters') || '{}')).length)
    setCartDirty(!!Object.keys(JSON.parse(sessionStorage.getItem('cart') || '{}')).length)
  }, [location])

  return (
    <div className={classes.root}>
      <div className={classes.opener} onClick={() => setOpened(!opened)}>
        {opened ? <MenuOpenedIcon /> : <MenuClosedIcon />}
      </div>
      <div className={classes.body}>
        <div className={classes.navItems} onMouseOver={() => width > 899 && setExpanded(true)} and onMouseOut={() => width > 899 && setExpanded(false)}>
          <div className={classes.navItem}>
            <div className={classes.navItemIcon}>
              <SearchIcon />
            </div>
            <div className={classes.navItemText}>
              <input
                className={classes.navItemInput}
                placeholder='пошук'
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && triggerSearch()}
              />
              <div className={classes.navItemButton} onClick={triggerSearch}><ArrowRight /></div>
            </div>
          </div>
          <div className={clsx(classes.navItem, history.location.pathname.includes('catalogue') ? '-active' : '')} onClick={onClick('/catalogue')}>
            <div className={classes.navItemIcon}>
              <CatalogueIcon />
            </div>
            <div className={classes.navItemText}>
              каталог
            </div>
          </div>
          <div className={clsx(classes.navItem, history.location.pathname.includes('filter') ? '-active' : '')} onClick={onClick('/filter')}>
            <div className={clsx(classes.navItemIcon, filtersDirty && classes.dirty)}>
              <FilterIcon />
            </div>
            <div className={classes.navItemText}>
              фільтр
            </div>
          </div>
          <div className={clsx(classes.navItem, history.location.pathname.includes('cart') ? '-active' : '')} onClick={onClick('/cart')}>
            <div className={clsx(classes.navItemIcon, cartDirty && classes.dirty)}>
              <CartIcon />
            </div>
            <div className={classes.navItemText}>
              корзина
            </div>
          </div>
          {/* <div className={clsx(classes.navItem, history.location.pathname.includes('catal23ogue') ? '-active' : '')} onClick={onClick('/')}>
            <div className={classes.navItemIcon}>
              <ComparisonIcon />
            </div>
            <div className={classes.navItemText}>
              порівняння
            </div>
          </div> */}
          <div className={clsx(classes.navItem, history.location.pathname.includes('info') ? '-active' : '')} onClick={onClick('/info')}>
            <div className={classes.navItemIcon}>
              <InformationIcon />
            </div>
            <div className={classes.navItemText}>
              інформація
            </div>
          </div>
          <div className={clsx(classes.navItem, history.location.pathname.includes('cooperation') ? '-active' : '')} onClick={onClick('/cooperation')}>
            <div className={classes.navItemIcon}>
              <CooperationIcon />
            </div>
            <div className={classes.navItemText}>
              співпраця
            </div>
          </div>
          <div className={clsx(classes.navItem, history.location.pathname.includes('contacts') ? '-active' : '')} onClick={onClick('/contacts')}>
            <div className={classes.navItemIcon}>
              <ContactsIcon />
            </div>
            <div className={classes.navItemText}>
              контакти
            </div>
          </div>
        </div>
      </div>
      <div className={classes.overlay} onClick={() => setOpened(false)}>
      </div>
    </div>
  );
}

export default Navigation;
