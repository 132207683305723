import React from 'react';
import { createUseStyles, useTheme } from "react-jss";
import { ReactComponent as LoaderIcon } from '../assets/loader.svg';


const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    minHeight: '70vh',

    '& svg': {
      animationName: 'spin',
      animationDuration: '1000ms',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    }
  },
});

const Loader = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.root}>
      <LoaderIcon />
    </div>
  );
}

export default Loader;
