import React from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { createUseStyles, useTheme } from "react-jss";
import clsx from 'clsx';
import { useHistory } from 'react-router';
// import useWindowDimensions from '../hooks/useWindowDimensions';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    background: ({ theme, variant }) =>  variant == 'default' ? theme.grayColor : theme.whiteColor,
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    fontSize: ({ theme }) => theme.fontSizeBody,
    color: ({ theme }) => theme.textColor,
    position: 'relative',

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      height: 'auto',
      minHeight: '100vh',
      display: 'block',
      padding: '80px 0',
    },
  },
  page: {
    background: ({ theme }) => theme.whiteColor,
    borderRadius: ({ variant }) => variant == 'default' ? '15px' : '0',
    height: '80vh',
    padding: ({ variant }) => variant == 'default' ? '50px 100px' : '0',
    boxShadow:  ({ variant }) => variant == 'default' ? '2px 4px 15px rgba(0, 0, 0, 0.05)' : '0',

    '@media screen and (max-width: 1800px)': {
      padding: ({ variant }) => variant == 'default' ? '50px 80px' : '0',
    },

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      padding: ({ variant }) => variant == 'default' ? '30px 45px' : '0',
      minHeight: '80vh',
      height: 'auto',
      margin: '0 auto',

    },

    '@media screen and (max-width: 1200px)': {
      margin: '0 50px 0 100px',
    },

    '@media screen and (max-width: 900px)': {
      margin: '0 15px',
    }
  },
  logo: {
    position: 'absolute',
    transform: "translate(-50%, 0)",
    left: '50%',
    top: '15px',
    display: 'flex',

    '@media screen and (max-width: 1200px)': {
      transform: 'none',
      left: '100px'
    }
  },
  blackFriday: {
    backgroundColor: 'rgb(32, 32, 32)',
    color: 'white',
    padding: '10px 450px 10px 50px',
    borderRadius: '20px',
    marginLeft: '50px',
    fontWeight: '700',
    display: 'flex',
    position: 'absolute',
    right: '-420px',
    top: '15px',

    '&>span': {
      margin: '0 10px',
      display: 'block',
    }
  },
  mediaPageWidth: {
    width: '1400px',

    '@media screen and (max-width: 1800px)': {
      width: '1200px',
    },

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      width: '1000px',
    },

    '@media screen and (max-width: 1200px)': {
      width: 'calc(100% - 150px)',
    },

    '@media screen and (max-width: 900px)': {
      width: 'calc(100% - 30px)',
    }
  }
});

const Layout = ({children, variant = 'default'}) => {
  const theme = useTheme();
  const classes = useStyles({ theme, variant });
  // const { width } = useWindowDimensions();
  const history = useHistory()

  return (
    <div className={classes.root}>
      <div className={clsx(classes.logo, classes.mediaPageWidth)}>
        <Logo onClick={() => history.push('/')} />
      </div>
      <div className={clsx(classes.page, classes.mediaPageWidth)}>
        {children}
        {/* <div className={classes.blackFriday}>
          <span>Black Friday!</span>
          {width > 900 &&
            <>
              <span>-5% на все!</span>
              <span>-10% на замовлення від 16 000 грн </span>
              <span>-15% на замовлення від 26 000 грн</span>
            </>
          }
        </div> */}
      </div>
    </div>
  );
}

export default Layout;
