import React, { useEffect } from 'react';
import { createUseStyles, useTheme } from "react-jss";
import Layout from '../components/Layout';
import { ReactComponent as ContactsImg } from '../assets/contactsImg.svg';
import { ReactComponent as Instagram } from '../assets/instagram.svg';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  },
  imageWrapper: {
    marginBottom: '80px',
  },
  textBlock: {
    marginBottom: '30px',
  },
  text: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    color: ({ theme }) => theme.textColor,
    marginTop: '10px',
  },
  link: {
    fontFamily: ({ theme }) => theme.fontFamilyDefault,
    color: '#454545',
    marginTop: '10px',
    '& path': {
      fill: '#454545',
    },
    '&:hover path': {
      fill: ({ theme }) => theme.mainColor,
    },
    '&:hover': {
      color: ({ theme }) => theme.mainColor,
    }
  },
});

const ContactsPage = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  useEffect(() => {
    document.title = 'Контакти - Christmas products'
  }, [])

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.imageWrapper}>
          <ContactsImg />
        </div>
        <div className={classes.textBlock}>
          <div className={classes.text}>
            Будемо раді відповісти на всі ваші запитання:
          </div>
          <a className={classes.link} href='mailto:newyear.product.foryou@gmail.com'>
            newyear.product.foryou@gmail.com
          </a>
        </div>
        <div className={classes.textBlock}>
          <div className={classes.text}>
            пн-пт 09:00-18:00(Viber/Telegram)
          </div>
          <a className={classes.link} href='tel:+380935118329'>
            +(380) 93 511 83 29
          </a>
        </div>
        <a className={classes.link} href='https://www.instagram.com/kvazar_ukraine'>
          <Instagram />
        </a>
      </div>
    </Layout>
  );
}

export default ContactsPage;
