import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from "react-jss";
import Layout from '../components/Layout';
import img01 from '../assets/info01.png';
import img02 from '../assets/info02.png';
import img03 from '../assets/info03.png';
import img11 from '../assets/info11.png';
import img12 from '../assets/info12.png';
import img13 from '../assets/info13.png';
import img14 from '../assets/info14.png';
import img21 from '../assets/info21.png';
import img22 from '../assets/info22.png';
import img23 from '../assets/info23.png';
import img24 from '../assets/info24.png';
import img31 from '../assets/info31.png';
import img32 from '../assets/info32.png';
import img41 from '../assets/info41.png';
import img42 from '../assets/info42.png';
import img43 from '../assets/info43.png';
import img51 from '../assets/info51.png';
import img52 from '../assets/info52.png';
import img53 from '../assets/info53.png';
import { ReactComponent as ArrowLeft } from '../assets/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../assets/arrowRight.svg';
import clsx from 'clsx';
import InfoSlide from '../components/InfoSlide';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  sliderNavBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    justifySelf: 'flex-end',
  },
  sliderPoints: {
    display: 'flex',

    '& span + span': {
      marginLeft: '30px',

      '@media screen and (max-width: 900px)': {
        marginLeft: '20px',
      },
    },
    '& span': {
      background: ({theme}) => theme.lightGrayColor,
      borderRadius: '50%',
      height: '6px',
      width: '6px',
      position: 'relative',
      transition: 'ease-in-out .2s all',
      display: 'block',
      cursor: 'pointer',
    },
    '& span.-active': {
      background: ({theme}) => theme.mainColor,
    },
    '& span:after': {
      background: ({theme}) => theme.whiteColor,
      opacity: '.1',
      transition: 'ease-in-out .2s all',
      content: '""',
      display: 'block',
      borderRadius: '50%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      height: '25px',
      width: '25px',
    },
    '& span:before': {
      background: ({theme}) => theme.whiteColor,
      opacity: '.3',
      transition: 'ease-in-out .2s all',
      content: '""',
      display: 'block',
      borderRadius: '50%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      height: '15px',
      width: '15px',
    },
    '& span.-active:before': {
      background: ({theme}) => theme.mainColor,
    },
    '& span.-active:after': {
      background: ({theme}) => theme.mainColor,
    },
  },
  sliderArrows: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '260px',

    '@media screen and (max-width: 900px)': {
      width: '130px',
    },
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    background: ({theme}) => theme.grayColor,
    borderRadius: '10px 0 0 10px',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    border: '0',

    '&:not(:disabled)': {
      cursor: 'pointer',
    },

    '&:hover:not(:disabled):not(:active)': {
      background: ({theme}) => theme.mainColor,

      '& path': {
        fill: ({theme}) => theme.whiteColor,
      }
    },

    '&:active:not(:disabled)': {
      background: '#6C55AE',
      '& path': {
        fill: ({theme}) => theme.whiteColor,
      }
    },

    '&:disabled': {
      '& path': {
        fill: ({theme}) => theme.lightGrayColor,
      }
    },
  },
  radiusLeft: {
    borderRadius: '10px 0 0 10px',
  },
  radiusRight: {
    borderRadius: '0 10px 10px 0',
  },
});

const InfoPage = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    document.title = 'Інформація - Christmas products'
  }, [])

  return (
    <Layout>
      <div className={classes.root}>
        <InfoSlide
          active={activeSlide==0}
          heading='Про нас'
          text='Вже понад 5 років ми займаємося продажем новорічних товарів за ціною виробника. Вся продукція, яку ми пропонуємо, виготовляється з високоякісної первинної сировини і проходить санітарну гігієну. Серед наших товарів ви можете обрати ялинку, гілку та вінок з тієї ж колекції. Ми постійно слідкуємо за трендами та кожного року оновлюємо наш асортимент'
          numbers={[[90, 'Видів новорічного декору'], [106, 'Різновидів ялинок']]}
          images={[<img src={img01} />, <img src={img02} />, <img src={img03} />]}
        />
        <InfoSlide
          active={activeSlide==1}
          heading='Ялинки з литої гілки'
          text='Це категорії ялинок, які виготовлені виколючно з литої пластикової гілки, скручені ниткою. Кожна гілочка відливається з щільного харчового пластику, поліпропилену, в індивідуальних формах. Новорічні вироби з литих гілок відносяться до найвищого класу товарів. Нижче ви можете бачити декілька видів литої гілки.'
          link='/catalogue?product_material=1'
          images={[<img src={img13} />, <img src={img14} />, <img src={img11} />, <img src={img12} />]}
        />
        <InfoSlide
          active={activeSlide==2}
          heading='Ялинки з Led-гірляндою'
          text='Ми дбаємо про ваш час, тому ми представляємоялинки, які можна придбати разом з гірляндою. Лампочки вже ретельно розподілені по гілках, тому прикрасити таку ялинку не займе багато зусиль. Ялинки складаються та розкладаються без проблем, оскільки герлянди між частинами дерева з’єднані за допомогою штекерів. '
          link='/catalogue?outside=pre_lit'
          images={[<img src={img24} />, <img src={img21} />, <img src={img23} />, <img src={img22} />]}
        />
        <InfoSlide
          active={activeSlide==3}
          heading='Ялинки на металевих шарнірах(крабах)'
          text='Особливістю цієї категорії являється полегшений спосіб збору ялинки. Після встановлення ялинки на ніжку всі гілочки падають і займають своє місце під правильним кутом відносно стувбура. При розкладанні ялинки, необхідно перевернути дереро та підв’язати мотузкою всі гілочки, що прилягають вгору до стовюура, зберігати в коробці'
          link='/catalogue?tree_trunk_types=1'
          images={[<img src={img31} />, <img src={img32} />]}
        />
        <InfoSlide
          active={activeSlide==4}
          heading='Ялинки вкриті штучним снігом'
          text='За бажанням Клієнта можливе засніження ялинки, сосни, гірлянди та інших декорацій. Засніжену ялинку не відрізнити від натуральної, хіба що, сніг не танутиме у Вашій оселі. Сніг виготовлений виключно з гіпоалергенних матеріалів та має всі сертифікати якості.'
          link='/catalogue?outside=snow'
          images={[<img src={img41} />, <img src={img43} />, <img src={img42} />]}
        />
        <InfoSlide
          active={activeSlide==5}
          heading='Новорічний декор'
          text='Ця категорія представляє усі вироби для декорування вашої домівки. Сюди входять вінки, гірлянди та лита гілка, з яких ви можете самостійно створити Ваш ідеальний новорічний декор.'
          link='/catalogue?product_type=гірлянда%2Cвінок'
          images={[<img src={img51} />, <img src={img52} />, <img src={img53} />]}
        />
        <div className={classes.sliderNavBar}>
          <div className={classes.sliderPoints}>
            {[0,1,2,3,4,5].map((el, i) => (
              <span
                onClick={() => setActiveSlide(i)}
                className={activeSlide === i ? '-active' : ''}
              />
            ))}
          </div>
          <div className={classes.sliderArrows}>
            <button
              className={clsx(classes.arrow, classes.radiusLeft)}
              onClick={() => activeSlide != 0 && setActiveSlide(activeSlide-1)}
              disabled={activeSlide == 0 ? 'disabled' : ''}
            >
              <ArrowLeft />
            </button>
            <button
              className={clsx(classes.arrow, classes.radiusRight)}
              onClick={() => activeSlide != 5 && setActiveSlide(activeSlide+1)}
              disabled={activeSlide == 5 ? 'disabled' : ''}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default InfoPage;
