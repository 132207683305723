import React from 'react';
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles({
  productCount: {
    background: ({theme}) => theme.whiteColor,
    borderRadius: '10px',
    width: '100px',
    height: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px',
    alignItems: 'center',
    zIndex: 7,
  },
  productCountInput: {
    outline: 'none',
    border: 'none',
    background: 'transparent',
    textAlign: 'center',
    marginRight: '-15px',
    fontFamily: ({theme}) => theme.fontFamilyBold,
    fontSize: ({theme}) => theme.fontSizeBody,
    maxWidth: '50px'
  },
  productCountButton: {
    background: ({theme}) => theme.grayColor,
    color: ({theme}) => theme.mainColor,
    fontSize: '20px',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const ProductCounter = ({ productCount, setProductCount }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.productCount}>
      <div className={classes.productCountButton} onClick={() => setProductCount(productCount + 1)}>
        +
      </div>
      <input className={classes.productCountInput} type='number' disabled value={productCount}/>
      <div className={classes.productCountButton} onClick={() => productCount > 1 && setProductCount(productCount - 1)}>
        –
      </div>
    </div>
  );
}

export default ProductCounter;
