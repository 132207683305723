import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles, useTheme } from "react-jss";
import { getAll } from '../api/Product';
import Layout from '../components/Layout';
import { ReactComponent as ArrowLeft } from '../assets/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../assets/arrowRight.svg';
import { ReactComponent as HeightIcon } from '../assets/height.svg';
import { ReactComponent as LengthIcon } from '../assets/length.svg';
import { ReactComponent as DiameterIcon } from '../assets/diameter.svg';
import clsx from 'clsx';
import Loader from '../components/Loader';
import { useHistory } from 'react-router';
import Empty from '../components/Empty';
import useWindowDimensions from '../hooks/useWindowDimensions';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    height: '100%'
  },
  topRow: {
    height: '40px',
    display: 'flex',
    width: '100%',

    '@media screen and (max-width: 900px)': {
      flexDirection: 'column',
    }
  },
  filters: {
    flex: '1 1 auto',
    display: 'flex'
  },
  pagination: {
    display:'flex',

    '&>div': {
      height: '40px',
      padding: '0 9px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',
      cursor: 'pointer'
    },

    '&>div.-active': {
      color: ({ theme }) => theme.mainColor,
      backgroundColor: ({ theme }) => theme.grayColor,
      fontWeight: 700
    },
    '&>div + div': {
      marginLeft: '10px',
    }
  },
  arrows: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    bottom: 0,
    position: 'absolute'
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    background: ({theme}) => theme.grayColor,
    borderRadius: '10px 0 0 10px',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    border: '0',

    '&:not(:disabled)': {
      cursor: 'pointer',
    },

    '&:hover:not(:disabled):not(:active)': {
      background: ({theme}) => theme.mainColor,

      '& path': {
        fill: ({theme}) => theme.whiteColor,
      }
    },

    '&:active:not(:disabled)': {
      background: '#6C55AE',
      '& path': {
        fill: ({theme}) => theme.whiteColor,
      }
    },

    '&:disabled': {
      '& path': {
        fill: ({theme}) => theme.lightGrayColor,
      }
    },
  },
  radiusLeft: {
    borderRadius: '10px 0 0 10px',
  },
  radiusRight: {
    borderRadius: '0 10px 10px 0',
  },
  products: {
    display: 'flex',
    height: '570px',
    minHeight: '570px',
    maxHeight: '570px',
  },
  productCard: {
    width: ({ perPage }) => perPage === 3 ? '33%' : '100%',
    position: 'relative',

    '&:hover .productCardInfoCard': {
      display: 'flex',

      '@media screen and (max-width: 900px)': {
        display: 'none',
      }
    },
    '& .productCardInfoCard': {
      display: 'none',
      position: 'absolute',
      bottom: 0,
      width: '80%',
      left: '10%',
      backgroundColor: ({theme}) => theme.whiteColor,
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '20px',
      paddingBottom: '20px',
      minHeight: '30%',
      borderRadius: '20px 20px 0 0',
      boxShadow: '2px -25px 15px rgba(0, 0, 0, 0.05)',
      zIndex: 5,
    },
    '& .productCardInfoName': {
      fontSize: ({theme}) => theme.fontSizeH2,
      textAlign: 'center',
    },
    '& .productCardInfoPrice': {
      fontFamily: ({theme}) => theme.fontFamilyBold,
      textAlign: 'center',
      paddingTop: '5px'
    },
  },
  productCardImage: {
    minHeight: '80%',
    height: '80%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
      width: 'auto',
      height: 'auto',
      maxWidth: '80%',
      maxHeight: '80%',
    }
  },
  productModification: {
    display: 'flex',
    width: '100%',
    padding: '5px 0',

    '&:not(:first-child)': {
      cursor: 'pointer',
    },

    '&>div': {
      flex: '1 1 33%',
      textAlign: 'right',
      width: '33%',
    },

    '&>div:first-child': {
      paddingLeft: '30px'
    },

    '&>div:last-child': {
      paddingRight: '30px'
    },
  },
  productModificationHeight: {
    textAlign: 'left!important',
  },
  productModificationPriceHeader: {
    color: ({theme}) => theme.mainColor,
    fontSize: '20px'
  },
  productModificationArrow: {
    color: ({theme}) => theme.mainColor,
    fontSize: '20px',
    lineHeight: 1
  },
  tag: {
    background: ({theme}) => theme.grayColor,
    padding: '4px 10px',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '30px',
    borderRadius: '10px'
  },
  tagDelete: {
    width: '17px',
    height: '17px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '10px',
    background: ({theme}) => theme.mainColor,
    color: ({theme}) => theme.whiteColor,
    fontSize: ({theme}) => theme.fontSizeH2,
    transform: 'rotate(45deg)',
    borderRadius: '50%',
    cursor: 'pointer',

    '&>span': {
      marginBottom: '-2px',
    }
  }
});

const CataloguePage = ({ location }) => {
  const theme = useTheme();
  const { height, width } = useWindowDimensions();
  const perPage = width < 900 ? 1 : 3;
  const classes = useStyles({ theme, perPage });
  const [products, setProducts] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [activePage, setActivePage] = useState(1)
  const [loading, setLoading] = useState(true)
  const possibleFilters = ['price', 'height', 'diameter', 'stand_types', 'product_material', 'product_type', 'colors', 'tree_trunk_types', 'outside']
  const history = useHistory()
  const [tags, setTags] = useState([])
  const pages = useMemo(() => {
    let res = []

    for(let i = 1; i<=totalPages; i++) {
      if (i == 1 || i == activePage - 1 || i == activePage || i == activePage + 1 || i == totalPages) {
        res.push(i);
      } else if (res[res.length-1] != '...') {
        res.push('...');
      }
    }

    return res
  }, [activePage, totalPages])

  const removeTag = (key) => {
    if(key=='searchString') {
      history.push({
        pathname: '/catalogue',
        search: removeParam(key)
      })
    } else {
      sessionStorage.setItem('filters', '{}')
      history.push({
        pathname: '/catalogue',
        search: ''
      })
    }
  }

  const removeParam = (key, defaultRtn) => {
    let rtn = (defaultRtn || location.search).split("?")[0],
        param,
        params_arr = [],
        queryString = ((defaultRtn || location.search).indexOf("?") !== -1) ? (defaultRtn || location.search).split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (let i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn
  }

  useEffect(() => {
    document.title = 'Каталог - Christmas products'
  }, [])

  useEffect(() => {
    setLoading(true)
    setActivePage(1)
    const sessionFilters = JSON.parse(sessionStorage.getItem('filters') || '{}')
    if(!location.search && Object.keys(sessionFilters).length) {
      history.push({
        pathname: '/catalogue',
        search: new URLSearchParams(sessionFilters).toString()
      })
      return;
    }
    const urlParams = new URLSearchParams(location.search);
    let newTags = ['searchString'].map((tagName) => [tagName, urlParams.get(tagName)]).filter((el) => el[1])
    if(possibleFilters.map((tagName) => [tagName, urlParams.get(tagName)]).filter((el) => el[1]).length)
      newTags.push(['filters', 'додаткові фільтри'])
    setTags(newTags)
    getAll(location.search).then((res) => {
      let productsToSet = res.products
      setProducts(productsToSet)
      setTotalPages(Math.ceil(res.total/perPage))
    }).finally(() => setLoading(false))
  }, [location])

  if(loading)
    return (
      <Layout>
        <Loader />
      </Layout>
    )

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.topRow}>
          <div className={classes.filters}>
            {tags.map((tag) =>
              <div className={classes.tag} key={tag}>
                <div className={classes.tagName}>
                  {tag[1]}
                </div>
                <div className={classes.tagDelete} onClick={() => removeTag(tag[0])}>
                  <span>+</span>
                </div>
              </div>
            )}
          </div>
          <div className={classes.pagination}>
            {pages.map((el) =>
              <div
                className={activePage == el ? '-active': ''}
                onClick={() => el !== '...' && setActivePage(el)}
                key={el}
              >
                {el}
              </div>
            )}
          </div>
        </div>
        {!products.length
          ? <Empty>Нічого не знайдено :(</Empty>
          : <>
              <div className={classes.products}>
                {products.slice((activePage-1)*perPage, activePage*perPage).map((product) =>
                  <div className={classes.productCard} key={product.id}>
                    <div className={classes.productCardImage} onClick={() => history.push(`/catalogue/${product.id}`)}>
                      <img src={product.image_url} />
                    </div>
                    <div className="productCardInfo">
                      <div className="productCardInfoName" onClick={() => history.push(`/catalogue/${product.id}`)}>
                        {product.name}
                      </div>
                      <div className="productCardInfoPrice" onClick={() => history.push(`/catalogue/${product.id}`)}>
                        {Math.ceil(product.product_modifications[0]?.price)} ₴ -  {Math.ceil(product.product_modifications[product.product_modifications.length - 1]?.price)} ₴
                      </div>
                    </div>
                    <div className="productCardInfoCard">
                      <div className={classes.productModification}>
                        <div className={classes.productModificationHeight}>
                          {product.product_type === 'вінок' && <DiameterIcon />}
                          {product.product_type === 'гірлянда' && <LengthIcon />}
                          {product.product_type === 'ялинка' && <HeightIcon />}
                        </div>
                        <div className={classes.productModificationPriceHeader}>
                          ₴
                        </div>
                        <div className={classes.productModificationArrow}>
                        </div>
                      </div>
                      {product.product_modifications.map((pm, i) =>
                        <div className={classes.productModification} onClick={() => history.push(`/catalogue/${product.id}/${pm.id}`)} key={`${i}-${pm.id}`}>
                          <div className={classes.productModificationHeight}>
                            {pm.height || pm.diameter}
                          </div>
                          <div className={classes.productModificationPrice}>
                            {Math.ceil(pm.price)}
                          </div>
                          <div className={classes.productModificationArrow}>
                            →
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.arrows}>
                <button
                  className={clsx(classes.arrow, classes.radiusLeft)}
                  onClick={() => activePage != 1 && setActivePage(activePage-1)}
                  disabled={activePage == 1 ? 'disabled' : ''}
                >
                  <ArrowLeft />
                </button>
                <button
                  className={clsx(classes.arrow, classes.radiusRight)}
                  onClick={() => activePage != totalPages && setActivePage(activePage+1)}
                  disabled={activePage == totalPages ? 'disabled' : ''}
                >
                  <ArrowRight />
                </button>
              </div>
            </>
        }
      </div>
    </Layout>
  );
}

export default CataloguePage;
