import React from 'react';
import { createUseStyles, useTheme } from "react-jss";
import { default as MuiSlider } from '@material-ui/core/Slider';
import useWindowDimensions from '../hooks/useWindowDimensions';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    fontSize: ({ theme }) => theme.fontSizeBody,
    height: '100%',
    maxHeight: '500px',

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      flexDirection: 'column',
      maxWidth: '500px'
    },

    '& .MuiSlider-root': {
      color: ({ theme }) => theme.mainColor
    }
  },
  controls: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingRight: '8px',

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      flexDirection: 'row-reverse'
    },
  },
  control: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      justifyContent: 'flex-start',
    },

    '& input:disabled': {
      width: '80px',
      height: '40px',
      border: ({ theme }) => `1px solid ${theme.lightGrayColor}`,
      background: ({ theme }) => theme.whiteColor,
      paddingRight: '10px',
      borderRadius: '13px',
      textAlign: 'right',
      marginLeft: '25px',
    }
  },
  slider: {
    height: 'calc(100% - 40px)',
    margin: '20px 0',

    '@media screen and (max-width: 1400px), screen and (max-height: 750px)': {
      height: 'auto',
      width: '100%',
    },
  }
});

const Slider = ({ valueOnChange, valuePair, minValue, maxValue, label, children }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { height, width } = useWindowDimensions();

  if(!valuePair) return null;

  if(width < 1400 || height < 750)
    return (
      <div className={classes.root}>
        {children}
        <div className={classes.slider}>
          <MuiSlider
            orientation={"horizontal"}
            min={minValue}
            max={maxValue}
            value={valuePair}
            onChange={valueOnChange}
          />
        </div>
        <div className={classes.controls}>
          <div className={classes.control}>
            <div className={classes.controlLabel}>
              до
            </div>
            <input value={`${valuePair[1]} ${label}`} disabled />
          </div>
          <div className={classes.control}>
            <div className={classes.controlLabel}>
              від
            </div>
            <input value={`${valuePair[0]} ${label}`} disabled />
          </div>
        </div>

      </div>
    );

  return (
    <div className={classes.root}>
      <div className={classes.controls}>
        <div className={classes.control}>
          <div className={classes.controlLabel}>
            до
          </div>
          <input value={`${valuePair[1]} ${label}`} disabled />
        </div>
        {children}
        <div className={classes.control}>
          <div className={classes.controlLabel}>
            від
          </div>
          <input value={`${valuePair[0]} ${label}`} disabled />
        </div>
      </div>
      <div className={classes.slider}>
        <MuiSlider
          orientation="vertical"
          min={minValue}
          max={maxValue}
          value={valuePair}
          onChange={valueOnChange}
        />
      </div>
    </div>
  );
}

export default Slider;
