import React from 'react';
import { createUseStyles, useTheme } from "react-jss";
import { useHistory } from 'react-router';
import selectArrowIconPath from '../assets/selectArrow.svg';
import selectArrowActiveIconPath from '../assets/selectArrowActive.svg';

const useStyles = createUseStyles({
  select: {
    display: 'flex',
    alignItems: 'center',
  },
  selectLabel: {
    marginRight: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  selectWrapper: {
    position: 'relative',
  },
  selectInput: {
    height: '30px',
    backgroundImage: `url(${selectArrowIconPath})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top 13px right 10px',
    backgroundColor: ({theme}) => theme.whiteColor,
    border: ({theme}) => `1px solid ${theme.grayColor}`,
    fontFamily: ({theme}) => theme.fontFamilyDefault,
    fontSize: ({theme}) => theme.fontSizeBody,
    width: '84px',
    borderRadius: '10px',
    position: 'absolute',
    top: '-15px',
    left: 0,
    zIndex: 9,

    '&.-opened': {
      backgroundImage: `url(${selectArrowActiveIconPath})`,
      boxShadow:'1px 2px 6px rgba(0, 0, 0, 0.1)',
      height: 'auto',

      '& .select-dropdown': {
        display: 'block',
      }
    },

    '&:focus': {
      outline: 'none',
      border: ({theme}) => `1px solid ${theme.mainColor}`,
    },

    '& .selected-value': {
      cursor: 'pointer',
      margin: '5px 0 0 10px'
    },

    '& .select-dropdown': {
      cursor: 'pointer',
      display: 'none',
      width: '100%',

      '& div': {
        padding: '3px 0 3px 10px',
        width: '100%',

        '&:hover': {
          background: ({theme}) => theme.grayColor,

        }
      }
    },
  },
});

const ProductSelect = ({ IconComponent, values, selectedValue, coreAttribute, navigateToPrepath }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();

  const selectOnClick = (e) => {
    if(e.target.parentNode.classList.contains('-opened')){
      e.target.parentNode.classList.remove("-opened");
    } else {
      e.target.parentNode.classList.add("-opened");
    }
  }

  const selectOnChange = (id) => (e) => {
    history.push(`${navigateToPrepath}/${id}`)
    e.target.parentNode.parentNode.classList.remove("-opened");
  }

  return (
    <div className={classes.select}>
      <div className={classes.selectLabel}>
        <IconComponent />
      </div>
      <div className={classes.selectWrapper}>
        <div className={classes.selectInput}>
          <div className='selected-value' onClick={selectOnClick}>{selectedValue[coreAttribute]}см</div>
          <div className='select-dropdown'>
            {values.map((pm) =>
              pm.id !== selectedValue.id ? <div onClick={selectOnChange(pm.id)} key={pm.id}>{pm[coreAttribute]}см</div> : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductSelect;
