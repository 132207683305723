import React from 'react';
import { createUseStyles, useTheme } from "react-jss";
import clsx from 'clsx';

const useStyles = createUseStyles({
  branchInfo: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 3,
  },
  standInfo: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 4,
  },
  treeTrunkInfo: {
    position: 'absolute',
    right: 0,
    bottom: '40%',
    zIndex: 5,
  },
  circle: {
    height: '200px',
    width: '200px',
    position: 'relative',

    '& .imageWrapper': {
      boxShadow: '3px 3px 9px rgba(0, 0, 0, 0.15)',
      borderRadius: '50%',
      overflow: 'hidden',
      display: 'flex',
      height: '200px',
      width: '200px',
      alignItems: 'center',
      background: ({theme}) => theme.whiteColor,
    },

    '& img': {
      minHeight: '100%',
      minWidth: '100%',
      height: 'auto',
      width: 'auto',
    },

    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      bottom: '-10px',
    }
  },
  contentCircle: {
    height: '300px',
    width: '300px',

    '& .imageWrapper': {
      height: '300px',
      width: '300px',
      padding: '0 30px',

      '&>div': {
        margin: 0,
      }
    },
  },
  branchCircle: {
    height: '300px',
    width: '300px',

    '@media screen and (max-height: 850px)': {
      height: '200px',
      width: '200px',
    },

    '& .imageWrapper': {
      height: '300px',
      width: '300px',

      '@media screen and (max-height: 850px)': {
        height: '200px',
        width: '200px',
      },
    },

    '& img': {
      transform: 'rotate(-45deg)',
    },

    '&::after': {
      width: '200px',
      height: "35px",
      borderTop: '1px solid #cdcdcd',
      borderRight: '1px solid #cdcdcd',
      left: '50%',
      bottom: '-50px',
    }
  },
  standCircle: {
    '&::after': {
      width: '260px',
      height: "35px",
      borderBottom: '1px solid #cdcdcd',
      borderRight: '1px solid #cdcdcd',
      left: '70%',
    }
  },
  treeTrunkCircle: {
    '&::after': {
      width: '260px',
      height: "35px",
      borderBottom: '1px solid #cdcdcd',
      borderLeft: '1px solid #cdcdcd',
      right: '70%',
      bottom: 0,
    }
  },
  contentInfo: {
    marginTop: '20px',
  },
  contentInfoItem: {
    color: ({theme}) => theme.darkerGrayColor,
    marginTop: '10px',

    '& span': {
      fontFamily: ({theme}) => theme.fontFamilyBold,
      color: ({theme}) => theme.textColor,
    }
  },
});

const ProductMainSpecification = ({ product, inSection }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  if(product.product_type === 'ялинка') {
    if(inSection) {
      return (
        <div className={classes.contentInfo}>
          <div className={classes.contentInfoItem}>
            <span>Колір:</span> {product.product_color}
          </div>
          <div className={classes.contentInfoItem}>
            <span>Матеріал:</span> {product.product_material}
          </div>
          <div className={classes.contentInfoItem}>
            <span>Засніження:</span> {product.snow ? 'так' : 'ні'}
          </div>
          <div className={classes.contentInfoItem}>
            <span>Led-гірлянда:</span> {product.pre_lit ? 'так' : 'ні'}
          </div>
          <div className={classes.contentInfoItem}>
            <span>Підставка:</span> {product.stand_type?.title}
          </div>
          <div className={classes.contentInfoItem}>
            <span>Тип стовбуру:</span> {product.tree_trunk_type?.title}
          </div>
        </div>
      );
    } else {
      return (<>
          <div className={classes.branchInfo}>
            <div className={clsx(classes.branchCircle, classes.circle)}>
              <div className='imageWrapper'>
                <img src={product.branch_image_url} />
              </div>
            </div>
            <div className={classes.contentInfo}>
              <div className={classes.contentInfoItem}>
                <span>Колір:</span> {product.product_color}
              </div>
              <div className={classes.contentInfoItem}>
                <span>Матеріал:</span> {product.product_material}
              </div>
              <div className={classes.contentInfoItem}>
                <span>Засніження:</span> {product.snow ? 'так' : 'ні'}
              </div>
              <div className={classes.contentInfoItem}>
                <span>Led-гірлянда:</span> {product.pre_lit ? 'так' : 'ні'}
              </div>
            </div>
          </div>
          <div className={classes.standInfo}>
            <div className={clsx(classes.standCircle, classes.circle)}>
              <div className='imageWrapper'>
                <img src={product.stand_type?.image_url} />
              </div>
            </div>
            <div className={classes.contentInfo}>
              <div className={classes.contentInfoItem}>
                <span>Підставка:</span> {product.stand_type?.title}
              </div>
            </div>
          </div>
          <div className={classes.treeTrunkInfo}>
            <div className={clsx(classes.treeTrunkCircle, classes.circle)}>
              <div className='imageWrapper'>
                <img src={product.tree_trunk_type?.image_url} />
              </div>
            </div>
            <div className={classes.contentInfo}>
              <div className={classes.contentInfoItem}>
                <span>Тип стовбуру:</span> {product.tree_trunk_type?.title}
              </div>
            </div>
          </div>
      </>)
    }
  }
  if(inSection) {
    return (
      <div className={classes.contentInfo}>
        <div className={classes.contentInfoItem}>
          <span>Колір:</span> {product.product_color}
        </div>
        <div className={classes.contentInfoItem}>
          <span>Матеріал:</span> {product.product_material}
        </div>
        <div className={classes.contentInfoItem}>
          <span>Засніження:</span> {product.snow ? 'так' : 'ні'}
        </div>
        <div className={classes.contentInfoItem}>
          <span>Led-гірлянда:</span> {product.pre_lit ? 'так' : 'ні'}
        </div>
      </div>
    );
  }
  return (
    <div className={classes.branchInfo}>
      <div className={clsx(classes.contentCircle, classes.circle)}>
        <div className='imageWrapper'>
          <div className={classes.contentInfo}>
            <div className={classes.contentInfoItem}>
              <span>Колір:</span> {product.product_color}
            </div>
            <div className={classes.contentInfoItem}>
              <span>Матеріал:</span> {product.product_material}
            </div>
            <div className={classes.contentInfoItem}>
              <span>Засніження:</span> {product.snow ? 'так' : 'ні'}
            </div>
            <div className={classes.contentInfoItem}>
              <span>Led-гірлянда:</span> {product.pre_lit ? 'так' : 'ні'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductMainSpecification;
